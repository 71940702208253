.settingsContainer {
  display: grid;
  grid-template-columns: 60px 250px auto 60px;
  column-gap: 4px;
  justify-self: start;
  align-self: auto;
  text-align: left;
  padding-bottom: 10px;
}

.settingsMenu {
  color: #444;
  padding: 4px;
  margin: 2px;
  visibility: var(--settingsMenuDisplay);
}

.settingsMenuSel {
  color: #444;
  padding: 4px;
  margin: 2px;
}

.settingsMenuSel, .settingsMenu:hover {
  background-color: #444;
  color: #BBB;
}

.settingsMenuContainer {
  padding-left: 8px;
}

.settingsMenuPadding {
  padding-left: 6px;
}

.settingsMenuEndItem {
  justify-self: center;
  align-self: center;
  display: inline-flex;
  align-items: center;
  border: 1px dashed #444;
  border-radius: 10px;
  padding: 3px;
  margin: 5px;
}
  
.settingsMenuEndItem:hover {
  border: 2px solid #444;
  padding: 2px;
}
  