
.wrapper {
  width: 100%;
  height: 100%;
}

.header {
  background: #333;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 92px;
  right: 0px;
  overflow: hidden;
}

.footer {
  background: #333;
  color: #999;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 32px;
  right: 0px;
  overflow: hidden;
}

.content {
  position: absolute;
  top: 92px;
  bottom: 32px;
  left: 0px;
  right: 0px;
  overflow: auto;
  background: #666;
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  grid-template-columns: 1fr auto 1fr;
}

.contentcenter {
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

:root {
  --rowNum: 1;
  --colNum: 1;
}

.panel-container {
  display: grid;
  grid-template-rows: repeat(var(--rowNum), auto);
  grid-template-columns: repeat(var(--colNum), auto);
  column-gap: 0px;
  row-gap: 0px;
  justify-items: stretch;
  align-items: stretch;
  justify-content: stretch;
  align-content: stretch;
}

.panel-item {
  grid-column-start: auto;
  grid-row-start: auto;
  margin: 0px;
}

.menu-container {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(20, auto) 1fr;
  column-gap: 4px;
  row-gap: 4px;
  justify-content: left;
  color: #999;
  position: sticky;
  top: 0;
  z-index: 100;
}

.menu-item {
  grid-column-start: auto;
  grid-row-start: auto;
  margin: 4px;
  align-self: start;
  display: grid;
  grid-template-rows: repeat(2, auto);
}

.menu-item:hover {
  color: #DDD;
}

.footer-container {
  display: grid;
  grid-template-columns: auto 1fr auto auto auto auto;
  column-gap: 4px;
  row-gap: 4px;
  justify-content: left;
  z-index: 100;
}

.footer-left {
  justify-self: start;
  align-self: center;
}

.footer-right {
  justify-self: end;
  align-self: center;
}

.menu-item-right {
  grid-column-start: 21;
  justify-self: end;
  align-self: center;
  margin-right: 26px;
}

.menu-item-part {
  grid-column-start: auto;
  grid-row-start: auto;
  margin: 1px;
  display: inline-flex;
  justify-self: center;
}

.submenu-container {
  display: grid;
  grid-template-rows: repeat(3, auto);
}

.submenu-container:hover {
  color: #999;
}

.submenu-item {
  grid-column-start: auto;
  grid-row-start: auto;
  margin: 1px;
  display: inline-flex;
  align-items: center;
}

.submenu-item:hover {
  color: #DDD;
}

.menu-vertical {
  border-left: 4px solid;
  height: 84px;
  color: #009688;
}

.overlay {
  background-color: rgba(0,0,0,0.5);
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.modalContainer {
  max-width: 600px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  background-color: #333;
  color: #DDD;
  box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.75);
  justify-content: center;
}

.modal-container5 {
  display: grid;
  grid-template-columns: 100px 100px 100px 100px 100px;
  column-gap: 10px;
  row-gap: 10px;
  justify-items: stretch;
  align-items: stretch;
  justify-content: stretch;
  align-content: stretch;
}

.modal-container3 {
  display: grid;
  grid-template-columns: 160px 160px 160px;
  column-gap: 10px;
  row-gap: 10px;
  justify-items: stretch;
  align-items: stretch;
  justify-content: stretch;
  align-content: stretch;
}

.modal-item {
  grid-column-start: auto;
  grid-row-start: auto;
  margin: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: white;
}

.modal-item-selected {
  border-style: solid;
  border-width: 2px;
  border-color: #009688;
}

.modal-content {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.LED-grey {
  color: #666;
}

.LED-yellow {
  color: yellow;
}

.LED-red {
  color: red;
}

.LED-green {
  color: green;
}

.LED-white {
  color: #FFD;
}

.overlaymarkerGreen {
  background-color: rgba(0,150,136,0.2);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 100px;
  z-index: 0;
}

.resize-modal {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 60px 200px 60px;
  column-gap: 4px;
  row-gap: 4px;
  justify-self: center;
  align-self: center;
}

.resize-modal-item {
  justify-self: center;
  align-self: center;
  display: inline-flex;
  margin-left: 4px;
  margin-right: 4px;
  align-items: center;
}

.modalEnd {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-self: center;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.modalEnd-item {
  justify-self: center;
  align-self: center;
  display: inline-flex;
  align-items: center;
  border: 1px dashed #999;
  border-radius: 10px;
  padding: 3px;
  margin: 5px;
}

.modalEnd-item:hover {
  border: 2px solid #999;
  padding: 2px;
}

a {
  text-decoration: none;
}

a:link, a:visited {
  color: #DDD;
}

.app-end {
  margin: 4px;
  color: #999;
}
